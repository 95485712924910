<template>
  <div>
    <Navbar :nav-light="true" />
    <Header/>
    <section class="section mt-60">
      <div class="container mt-lg-3">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <Sidebar/>
          </div>
          <div class="col-lg-8 col-12">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>

<script>
  import { ArrowUpIcon, } from 'vue-feather-icons';
  import Navbar from "@/components/core/Navbar";
  import Footer from "@/components/core/Footer";
  import Header from "@/components/my-account/Header";
  import Sidebar from "@/components/my-account/Sidebar";
  export default {
    name: "MyAccount",
    components: {
      Navbar,
      Footer,
      Header,
      Sidebar,
      ArrowUpIcon,
    },
  };
</script>