<template>
  <header id="topnav" class="defaultscroll sticky" :class="{ 'bg-white': isWhiteNavbar === true }">
    <div class="container">
      <div>
        <router-link class="logo" to="/" v-if="navLight !== true">
          <img v-bind:src="logo.dark" height="70" alt="" />
        </router-link>
        <router-link class="logo" to="/" v-else>
          <img v-bind:src="logo.dark" class="l-dark" height="70" alt="" />
          <img v-bind:src="logo.light" class="l-light" height="70" alt="" />
        </router-link>
      </div>
      <div class="buy-button" v-if="isIcons !== true">
        <a href="#pricing" class="btn" :class="{
            'btn-light': navLight === true,
            'btn-primary': navLight !== true,
          }">Check our prices</a>
      </div>
      <ul class="buy-button list-inline mb-0" v-if="isIcons === true">
        <li class="list-inline-item mb-0 developer-icon">
          <b-dropdown right variant="link" toggle-class="text-decoration-none p-0 pr-2"
            menu-class="dd-menu dropdown-menu-right bg-white shadow rounded border-0 mt-3 py-0">
            <template #button-content>
              <i class="mdi mdi-magnify h4 text-muted"></i>
            </template>
            <div style="width: 300px">
              <form>
                <input type="text" id="text" name="name" class="form-control border bg-white"
                  placeholder="Search..." />
              </form>
            </div>
          </b-dropdown>
        </li>
        <li class="list-inline-item mb-0 pr-2">
          <a href="#" class="btn btn-icon btn-soft-primary"><i class="mdi mdi-github mdi-18px icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 pr-2">
          <a href="#" class="btn btn-icon btn-soft-primary"><i class="mdi mdi-stack-overflow mdi-18px icons"></i></a>
        </li>
        <li class="list-inline-item mb-0">
          <a href="javascript:void(0)" class="btn btn-icon btn-soft-primary" data-toggle="modal"
            data-target="#productview"><i class="mdi mdi-account-outline mdi-18px icons"></i></a>
        </li>
      </ul>
      <div class="menu-extras">
        <div class="menu-item">
          <a class="navbar-toggle" @click="toggleMenu()" :class="{ open: isCondensed === true }">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </div>
      </div>

      <div id="navigation">
        <ul class="navigation-menu" :class="{ 'nav-light': navLight === true }">
          <li>
            <router-link to="/" class="side-nav-link-ref">Home</router-link>
          </li>
          <li>
            <router-link to="/preguntas-frecuentes" class="side-nav-link-ref">Preguntas frecuentes</router-link>
          </li>
          <li>
            <router-link to="/somos" class="side-nav-link-ref">Somos</router-link>
          </li>
          <li>
            <router-link to="/exclusiones">Exclusiones</router-link>
          </li>
          <li v-if="authenticated">
            <router-link to="/account-messages" class="side-nav-link-ref">My Account</router-link>
          </li>
          <li v-else>
            <router-link to="/auth/login" class="side-nav-link-ref">Login</router-link>
          </li>
        </ul>
        <div class="menu-extras">
          <div class="menu-item">
            <a class="navbar-toggle" @click="toggleMenu()" :class="{ open: isCondensed === true }">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    data() {
      return {
        isCondensed: false,
      };
    },
    props: {
      isWhiteNavbar: {
        type: Boolean,
      },
      navLight: {
        type: Boolean,
      },
      isIcons: {
        type: Boolean,
      },
    },
    computed: {
      authenticated() {
        return !!this.$store.state.auth.user;
      },
      logo() {
        return this.$store.getters["system/logo"];
      },
    },
    methods: {
      /**
       * Toggle menu
       */
      toggleMenu() {
        this.isCondensed = !this.isCondensed;
        if (this.isCondensed) {
          document.getElementById("navigation").style.display = "block";
        } else document.getElementById("navigation").style.display = "none";
      },

      /**
       * Menu clicked show the submenu
       */
      onMenuClick(event) {
        event.preventDefault();
        const nextEl = event.target.nextSibling.nextSibling;

        if (nextEl && !nextEl.classList.contains("open")) {
          const parentEl = event.target.parentNode;
          if (parentEl) {
            parentEl.classList.remove("open");
          }
          nextEl.classList.add("open");
        } else if (nextEl) {
          nextEl.classList.remove("open");
        }
        return false;
      },
    },
    mounted: () => {
      window.onscroll = function () {
        onwindowScroll();
      };

      function onwindowScroll() {
        const topnav    = document.getElementById("topnav");
        const backToTop = document.getElementById("back-to-top");

        if (topnav) {
          if (
            document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50
          ) {
            topnav.classList.add("nav-sticky");
          } else {
            topnav.classList.remove("nav-sticky");
          }
        }

        if (backToTop) {
          if (
            document.body.scrollTop > 100 ||
            document.documentElement.scrollTop > 100
          ) {
            backToTop.style.display = "inline";
          } else {
            backToTop.style.display = "none";
          }
        }
      }

      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i];
          break;
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.add("active");
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("active");
              const parent4 = parent3.parentElement;
              if (parent4) {
                const parent5 = parent4.parentElement;
                parent5.classList.add("active");
              }
            }
          }
        }
      }
    },
  };
</script>