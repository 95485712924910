<template>
  <div class="custom-footer">
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img v-bind:src="logo.light" height="120" alt="" />
            </a>
            <p class="mt-4">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quae minus, eaque amet odio accusantium facere
              iusto molestiae fugit enim ad nesciunt .
            </p>
            <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item ml-1">
                <a href="https://www.linkedin.com/showcase/aig-brasil" target="_blank" class="rounded">
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Company</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> About
                  us</router-link>
              </li>
              <li>
                <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                  Services</router-link>
              </li>
              <li>
                <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Team</router-link>
              </li>
              <li>
                <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i>
                  Pricing</router-link>
              </li>
              <li>
                <a href="https://www.negocioseguroaig.com.br/" target="_blank" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> Blog</a>
              </li>
              <li>
                <a href="https://www.aig.com.br/" target="_blank" class="text-foot">
                  <i class="mdi mdi-chevron-right mr-1"></i> Site Institutional</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Usefull Links</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Terms of
                  Services</router-link>
              </li>
              <li>
                <router-link to="/" class="text-foot"><i class="mdi mdi-chevron-right mr-1"></i> Privacy
                  Policy</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Newsletter</h5>
            <p class="mt-4">Sign up and receive the latest tips via email.</p>
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="foot-subscribe form-group">
                    <label>Write your email
                      <span class="text-danger">*</span></label>
                    <div class="position-relative">
                      <mail-icon class="fea icon-sm icons"></mail-icon>
                      <input type="email" name="email" id="emailsubscribe" class="form-control pl-5 rounded"
                        placeholder="Your email : " required />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <input type="submit"
                    id="submitsubscribe"
                    name="send"
                    style="color: #ffffff!important"
                    class="btn btn-soft-primary btn-block"
                    value="Subscribe"/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © 2021 - {{ new Date().getFullYear() }}.
              </p>
            </div>
          </div>
          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-right mb-0">
              <li class="list-inline-item">
                <a href="javascript:void(0)"><img src="/images/payments/american-ex.png" class="avatar avatar-ex-sm"
                    title="American Express" alt="" /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"><img src="/images/payments/discover.png" class="avatar avatar-ex-sm"
                    title="Discover" alt="" /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"><img src="/images/payments/master-card.png" class="avatar avatar-ex-sm"
                    title="Master Card" alt="" /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"><img src="/images/payments/paypal.png" class="avatar avatar-ex-sm"
                    title="Paypal" alt="" /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"><img src="/images/payments/visa.png" class="avatar avatar-ex-sm"
                    title="Visa" alt="" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  } from "vue-feather-icons";
  export default {
    components: {
      FacebookIcon,
      InstagramIcon,
      TwitterIcon,
      LinkedinIcon,
      MailIcon,
    },
    computed: {
      logo() {
        return this.$store.getters["system/logo"];
      },
    },
  };
</script>

<style scoped>
  .custom-footer {
    left     : 50%!important;
    position : relative;
    transform: translateX(-50%);
    width    : 100vw!important;
  }
</style>